import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import Button from "./Button";
import useLocalization from "../../Common/Localization/UseLocalization";

interface EvFilterSettingsProps {
  show?: boolean;
  onClose?: () => void;
}

function EvFilterSettings({ show, onClose }: EvFilterSettingsProps) {
  const { filters, restaurants, carChargerCompanies } = useRestaurantMap();
  const dispatch = useRestaurantMapDispatch();
  const localization = useLocalization();

  const setCompanyFilter = (companies: string[]) => {
    dispatch?.setFilters({
      ...filters,
      ev: {
        ...filters.ev,
        company: companies,
      },
    });
  };

  const setKilowattFilter = (
    kw: number | null,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const kilowatt = e.currentTarget.checked ? kw : null;

    dispatch?.setFilters({
      ...filters,
      ev: {
        ...filters.ev,
        kilowatt,
      },
    });
  };

  const onCompanyCheck = (
    company: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const companies = e.currentTarget.checked
      ? [...filters.ev.company, company]
      : filters.ev.company.filter((c) => c != company);

    setCompanyFilter(companies);
  };

  const onCompanyAllCheck = () => {
    setCompanyFilter(
      filters.ev.company.length == carChargerCompanies.length
        ? []
        : carChargerCompanies
    );
  };

  const allChecked = filters.ev.company.length == carChargerCompanies.length;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          layout
          className="map-filter__settings-pane"
          initial={{ y: 600 }}
          exit={{ y: 600 }}
          animate={{ y: 0 }}
        >
          <div>
            <div className="map-filter__settings-pane__section">
              <h4 className="map-filter__settings-pane__heading">
                {localization("/pages/restaurantlistpage/ev-charger-company")}
              </h4>
              <div className="map-filter__checkbox-container">
                <label className="map-filter__checkbox-label map-filter__checkbox-label--bold">
                  <input
                    type="checkbox"
                    className="map-filter__checkbox"
                    checked={allChecked}
                    onChange={() => onCompanyAllCheck()}
                  />
                  {localization(
                    "/pages/restaurantlistpage/ev-charger-show-all-company"
                  )}
                </label>
              </div>
              {carChargerCompanies.map((c) => (
                <div key={c} className="map-filter__checkbox-container">
                  <label className="map-filter__checkbox-label">
                    <input
                      type="checkbox"
                      name={`company-${c}`}
                      className="map-filter__checkbox"
                      checked={filters.ev.company?.includes(c)}
                      onChange={(e) => onCompanyCheck(c, e)}
                    />
                    {localization(
                      `/property/enum/carchargercompany/${c.toLowerCase()}`
                    )}
                  </label>
                </div>
              ))}
            </div>
            <div className="map-filter__settings-pane__section">
              <h4 className="map-filter__settings-pane__heading">Effekt</h4>
              <label className="map-filter__checkbox-label map-filter__checkbox-label--bold">
                <input
                  type="checkbox"
                  className="map-filter__checkbox"
                  checked={filters.ev.kilowatt == null}
                  onChange={(e) => setKilowattFilter(null, e)}
                />
                {localization(
                  "/pages/restaurantlistpage/ev-charger-show-all-kilowatt"
                )}
              </label>
              <label className="map-filter__checkbox-label">
                <input
                  type="checkbox"
                  className="map-filter__checkbox"
                  checked={
                    filters.ev.kilowatt != null && filters.ev.kilowatt == 150
                  }
                  onChange={(e) => setKilowattFilter(150, e)}
                />
                {localization(
                  "/pages/restaurantlistpage/ev-charger-kilowatt-gte150"
                )}
              </label>
            </div>
          </div>
          <div className="map-filter__settings-pane__footer">
            <Button
              label={
                localization("/pages/restaurantlistpage/remove-filter") ??
                "Remove"
              }
              theme="outline"
              onClick={() =>
                dispatch?.setFilters({
                  ...filters,
                  ev: { ...filters.ev, hasCharger: false },
                })
              }
            />
            <Button
              label={
                localization("/pages/restaurantlistpage/use-filter") ?? "Use"
              }
              theme="solid"
              color="green"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default EvFilterSettings;
