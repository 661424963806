import React from "react";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import Button from "./Button";
import { Search } from "react-feather";
import useLocalization from "../../Common/Localization/UseLocalization";
import ScrollContainer from "react-indiana-drag-scroll";
import { MdMyLocation } from "react-icons/md";
import { BsLightningCharge, BsCarFront } from "react-icons/bs";
import LocationSearchInput from "./LocationSearchInput";

interface FilterControlsProps {
  onEvClick: () => void;
}

const FilterControls = ({ onEvClick }: FilterControlsProps) => {
  const localization = useLocalization();
  const { filters, geolocation } = useRestaurantMap();
  const dispatch = useRestaurantMapDispatch();

  return (
    <>
      <div className="map-window__layout">
        <LocationSearchInput />
      </div>
      <div className="map-filter__buttons__outer-container">
        <ScrollContainer className="map-filter__buttons__container">
          <Button
            theme="outline"
            color="red"
            active={geolocation}
            className="map-filter__buttons__button"
            label={
              localization("/pages/restaurantlistpage/use-location") ??
              "Show close"
            }
            icon={MdMyLocation}
            onClick={() => dispatch?.setGeolocation(!geolocation)}
          />
          <Button
            theme="outline"
            color="yellow"
            active={filters.ev.hasCharger}
            label={
              localization("/pages/restaurantlistpage/ev-charger-filter") ??
              "Car charger"
            }
            className="map-filter__buttons__button"
            icon={BsLightningCharge}
            onClick={() => {
              dispatch?.setFilters({
                ...filters,
                ev: { ...filters.ev, hasCharger: !filters.ev.hasCharger },
              });
              if (onEvClick) {
                onEvClick();
              }
            }}
          />
          <Button
            theme="outline"
            color="blue"
            active={filters.driveIn}
            label={
              localization("/pages/restaurantlistpage/drive-in-filter") ??
              "Drive-in"
            }
            className="map-filter__buttons__button"
            icon={BsCarFront}
            onClick={() =>
              dispatch?.setFilters({ ...filters, driveIn: !filters.driveIn })
            }
          />
        </ScrollContainer>
      </div>
    </>
  );
};

export default FilterControls;
