class Utilities {
  static init() {
    /**
    * Scrolls to top
    */
    const toTopButton = document.querySelector('.o-to-top-button');

    if (toTopButton !== null) {
      toTopButton.addEventListener('click', this.scrollToTop, true);
      window.addEventListener('scroll', () => {
        if (window.scrollY > (window.innerHeight * 1.5)) {
          toTopButton.classList.add('o-to-top-button_visible');
        } else {
          toTopButton.classList.remove('o-to-top-button_visible');
        }
      });
    }

    /**
    * Opens the print dialog on click
    */
    const printButton = document.querySelector('.js-print-page');

    if (!printButton || printButton.length === 0) {
      return;
    }

    printButton.addEventListener('click', this.printPage, true);
  }

  // scroll to top
  static scrollToTop() {
    document.querySelector('body').scrollIntoView({
      behavior: 'smooth',
    });
  }

  // print page
  static printPage(e) {
    e.preventDefault();

    window.print();
  }

  // set cookie
  static setCookie(name, value, days) {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
  }

  // get cookie
  static getCookie(name) {
    const cookieName = name;
    const re = new RegExp(`${cookieName}=([^;]+)`);
    const value = re.exec(document.cookie);

    return (value != null) ? unescape(value[1]) : null;
  }

  // Get next sibling that matches selector
  static getNextSibling(elem, selector) {
    let sibling = elem.nextElementSibling;
    let match = null;
    let matchesFn = null;

    // find vendor prefix
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    });

    if (!selector) {
      match = sibling;
    } else {
      while (sibling) {
        if (sibling[matchesFn](selector)) {
          match = sibling;
          break;
        } else {
          sibling = sibling.nextElementSibling;
        }
      }
    }

    return match;
  }

  // find closest ancestor by selector
  static findAncestor(el, sel) {
    let element = el;
    let matchesFn = null;

    // find vendor prefix
    ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
      if (typeof document.body[fn] === 'function') {
        matchesFn = fn;
        return true;
      }
      return false;
    });

    if (typeof element.closest === 'function') {
      return element.closest(sel) || null;
    }
    while (element) {
      if (element[matchesFn](sel)) {
        return element;
      }
      element = element.parentElement;
    }
    return null;
  }
}

export default Utilities;
