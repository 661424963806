import React from "react";
import { LocalizationContext } from "../Common/Localization/LocalizationContext";
import {
  RestaurantMapFilters,
  RestaurantMapProvider,
  StartCoordinates,
} from "./RestaurantMapContext";
import RestaurantMap from "./components/RestaurantMap";
import { Restaurant } from "./types";

interface RestaurantMapAppProps {
  i18n: { [key: string]: string };
  heading: string;
  countryId: string;
  restaurants: Restaurant[];
  googleMapsApiKey: string;
  startCoordinates?: StartCoordinates;
  filterEvCharger?: boolean;
}

function RestaurantMapApp({
  i18n,
  filterEvCharger,
  ...props
}: RestaurantMapAppProps) {
  const startFilters: Partial<RestaurantMapFilters> = filterEvCharger
    ? { ev: { hasCharger: true, company: [], kilowatt: null } }
    : {};
  console.log(filterEvCharger, startFilters);
  return (
    <LocalizationContext.Provider
      value={{ countryId: props.countryId, ...i18n }}
    >
      <RestaurantMapProvider
        restaurants={props.restaurants}
        startCoordinates={props.startCoordinates}
        startFilters={startFilters}
      >
        <RestaurantMap
          googleMapsApiKey={props.googleMapsApiKey}
          heading={props.heading}
        />
      </RestaurantMapProvider>
    </LocalizationContext.Provider>
  );
}

export default RestaurantMapApp;
