import React, { ReactNode } from "react";

export interface WindowHeadingLink {
  label: string;
  onClick: () => void;
  className?: string;
}

interface WindowHeadingProps {
  title: string;
  subtitle?: string;
  links?: WindowHeadingLink[];
}

const WindowHeading = ({ title, subtitle, links }: WindowHeadingProps) => {
  return (
    <div className="map-window__layout">
      <div className="map-window__heading">
        <div className="map-window__heading__title">
          <h1>{title}</h1>
          {subtitle && <span>{subtitle}</span>}
        </div>
        <div className="map-window__heading__links">
          {links?.map((l, i) => (
            <a key={i} href="#" className={l.className} onClick={l.onClick}>
              {l.label}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WindowHeading;
