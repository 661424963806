/**
* Off canvas navigation
*/
class SiteNav {
  static init() {
    const toggler = document.querySelector('.js-off-canvas-toggler');
    const nav = document.querySelector('.js-off-canvas');
    const topBar = document.querySelector('.js-mobile-topbar');
    const subNavToggle = document.querySelectorAll('.js-toggle-sub-nav');

    toggler.addEventListener('click', (e) => {
      e.stopPropagation();
      nav.classList.toggle('is-active');
      topBar.classList.toggle('is-active');
    });

    [...subNavToggle].forEach((button) => {
      button.addEventListener('click', this.toggleSubNav, true);
    });

    this.detectScrollDirection();
  }

  static toggleSubNav() {
    const currentClassName = 'js-current-nav-item';
    const oldCurrentContainer = document.querySelector(`.${currentClassName}`);
    const subNavContainer = this.parentElement;
    const thisIsCurrent = subNavContainer.classList.contains(currentClassName);

    if (oldCurrentContainer) {
      oldCurrentContainer.classList.remove(currentClassName, 'm-navbar__nav-item--current');
      oldCurrentContainer.querySelector('.js-toggle-sub-nav').innerHTML = '<span class="m-navbar__sub-nav-toggle--plus">+</span>';
    }

    if (!thisIsCurrent) {
      subNavContainer.classList.add(currentClassName, 'm-navbar__nav-item--current');
      this.innerHTML = '<span class="m-navbar__sub-nav-toggle--minus">-</span>';
    }
  }

  static detectScrollDirection() {
    window.addEventListener('scroll', () => {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const header = document.querySelector('.js-mobile-topbar');

      if (header) {
        if (scrollY <= Math.max(this.lastScroll, 300)
          || window.innerWidth >= 768
          || this.loaded === undefined) {
          header.classList.remove('m-navbar__mobile-topbar--scrolled');
        } else {
          header.classList.add('m-navbar__mobile-topbar--scrolled');
        }

        this.lastScroll = scrollY;
        this.loaded = true;
      }
    });
  }
}

export default SiteNav;
