import feather from "feather-icons";
import React from "react";
import { createRoot } from "react-dom/client";

import SiteNav from "./modules/site-nav";
import Carousel from "./modules/carousel";
import Dropdown from "./modules/dropdown";
import Utilities from "./modules/utilities";
import FilterRecipes from "./modules/filter-recipes";
import VideoArchive from "./modules/video-archive";
import NutrientTables from "./modules/nutrient-tables";
import ProductContentTables from "./modules/product-content-tables";
import Forms from "./modules/forms";
import LanguageModal from "./modules/language-modal";
import StoreFinder from "./modules/store-finder";
import Tabs from "./modules/tabs";
import Collapse from "./modules/collapse";
import RestaurantMapApp from "./apps/RestaurantMap/RestaurantMapApp";

document.addEventListener("DOMContentLoaded", () => {
  // Feather icons
  feather.replace();

  // init js modules
  SiteNav.init();
  Dropdown.init();
  Carousel.init();
  Utilities.init();
  FilterRecipes.init();
  VideoArchive.init();
  NutrientTables.init();
  ProductContentTables.init();
  Forms.init();
  LanguageModal.init();
  StoreFinder.init();
  Tabs.init();
  Collapse.init();

  // init react apps
  document.querySelectorAll("[data-app]").forEach((e) => {
    const root = createRoot(e);
    const params = new URL(document.location).searchParams;
    const props = JSON.parse(e.getAttribute("data-props"));
    const filterEvCharger = params.get("ev-charger") == true ? true : false;

    switch (e.getAttribute("data-app")) {
      case "RestaurantList":
        root.render(
          <RestaurantMapApp {...props} filterEvCharger={filterEvCharger} />
        );
        break;
      default:
        console.warn(`Unkown react app: ${e.getAttribute("data-app")}`);
    }
  });

  // eslint-disable-next-line no-console
  console.log(
    `%c

    @@@@@@@@@@@@@@ @@@@@@@@@@@@@
    @@@@@@@ @@@@@@ @@@@@@@@@@@@@
          @ @      @@@@      @@@
          @ @      @@@@@@@@@@@@@@@
          @ @      @@@@@@@@@@@@@@@
          @ @      @@@@       @@@@
          @ @      @@@@       @@@@

                   @@@@@@@@@@@@@@@ @@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@ @@@@@@ @@@@@@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@       @@@@ @@@  @ @   @@@
                   @@@@@@@@@@@@@@@ @@@  @ @   @@@
                   @@@@@@@@@@@@@@@ @@@  @ @   @@@

                   @@@@@@@@@@@@@@@
                   @@@@       @@@@
                   @@@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@
                   @@@@       @@@@
                   @@@@@@@@@@@@@@@
                   @@@@@@@@@@@@@@@

  `,
    "font-family:monospace; font-size: 8px; line-height: 4px; color:#3F8D77; text-shadow: 1px 1px 1px #000"
  );

  // eslint-disable-next-line no-console
  console.log("Developed by www.tromb.com");
});
