import React, { ReactNode, createContext, useContext, useReducer } from "react";
import { CarCharger, Position, Restaurant } from "./types";

export interface RestaurantMapFilters {
  adress: string | null;
  ev: {
    hasCharger: boolean;
    company: string[];
    kilowatt: number | null;
  };
  driveIn: boolean;
}

export type StartCoordinates = { lat?: number; lng?: number; zoom?: number };

export interface RestaurantMapData {
  restaurants: Restaurant[];
  filteredRestaurants: Restaurant[];
  carChargerCompanies: string[];
  activeRestaurant: Restaurant | null;
  geolocation: boolean;
  geolocationPosition?: Position;
  focusPosition: Position | null;
  filters: RestaurantMapFilters;
  startCoordinates?: StartCoordinates;
}

export interface RestaurantMapDispatchData {
  setActiveRestaurant: (payload: Restaurant | null) => void;
  setFilters: (payload: RestaurantMapFilters) => void;
  resetFilters: () => void;
  setGeolocation: (payload: boolean) => void;
  setGeolocationPosition: (payload: Position) => void;
  setFocusPosition: (payload: Position) => void;
}

const initialValue: RestaurantMapData = {
  restaurants: [],
  filteredRestaurants: [],
  carChargerCompanies: [],
  startCoordinates: undefined,
  activeRestaurant: null,
  geolocation: true,
  focusPosition: null,
  filters: {
    adress: null,
    ev: {
      hasCharger: false,
      company: [],
      kilowatt: null,
    },
    driveIn: false,
  },
};

type RestaurantMapDispatchFunction = (payload: any) => void;

export const RestaurantMapContext =
  createContext<RestaurantMapData>(initialValue);
export const RestaurantMapDispatchContext =
  createContext<null | RestaurantMapDispatchFunction>(null);

function getCarChargerCompanies(restaurants: Restaurant[]) {
  const companies = restaurants
    .filter((r) => r.carChargers.length > 0)
    .flatMap((r) => r.carChargers.map((cc) => cc.company));

  // Return only unique values in companies array
  return companies.filter((c, i) => companies.indexOf(c) == i);
}

function evCompanyFilter(
  carChargers: CarCharger[],
  filters: RestaurantMapFilters
) {
  return filters.ev.company.length == 0
    ? carChargers
    : carChargers.filter((cc) => filters.ev.company.includes(cc.company));
}

function evKilowattFilter(
  carChargers: CarCharger[],
  filters: RestaurantMapFilters
) {
  return filters.ev.kilowatt == null
    ? carChargers
    : carChargers.filter((cc) => cc.kilowatt >= filters.ev.kilowatt!);
}

function getFilteredRestaurants(
  restaurants: Restaurant[],
  filters: RestaurantMapFilters
) {
  let filteredRestaurants = restaurants.filter(
    (r) => !filters.driveIn || r.hasDriveIn
  );

  if (filters.ev.hasCharger) {
    filteredRestaurants = filteredRestaurants
      .filter((r) => r.carChargers.length > 0)
      .filter(
        (r) =>
          evCompanyFilter(evKilowattFilter(r.carChargers, filters), filters)
            .length > 0
      );
  }

  return filteredRestaurants;
}

function reducer(
  data: RestaurantMapData,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case "setGeolocation": {
      return { ...data, geolocation: action.payload };
    }
    case "setGeolocationPosition": {
      return { ...data, geolocationPosition: action.payload };
    }
    case "setFilters": {
      const filteredRestaurants = getFilteredRestaurants(
        data.restaurants,
        action.payload
      );
      return { ...data, filteredRestaurants, filters: action.payload };
    }
    case "resetFilters": {
      return {
        ...data,
        filteredRestaurants: data.restaurants,
        filters: initialValue.filters,
      };
    }
    case "setFocusPosition": {
      return { ...data, focusPosition: action.payload };
    }
    case "setActiveRestaurant": {
      return { ...data, activeRestaurant: action.payload };
    }
    default: {
      throw Error("Unknown action: " + action.type);
    }
  }
}

export function useRestaurantMap() {
  return useContext(RestaurantMapContext);
}

export function useRestaurantMapDispatch(): RestaurantMapDispatchData | null {
  const dispatch = useContext(RestaurantMapDispatchContext);
  if (!dispatch) {
    return null;
  }

  return {
    setFilters: (payload) => dispatch({ type: "setFilters", payload }),
    resetFilters: () => dispatch({ type: "resetFilters", payload: null }),
    setGeolocation: (payload) => dispatch({ type: "setGeolocation", payload }),
    setGeolocationPosition: (payload) =>
      dispatch({ type: "setGeolocationPosition", payload }),
    setActiveRestaurant: (payload) =>
      dispatch({ type: "setActiveRestaurant", payload }),
    setFocusPosition: (payload) =>
      dispatch({ type: "setFocusPosition", payload }),
  };
}

interface RestaurantMapProviderProps {
  children: ReactNode;
  restaurants: Restaurant[];
  startCoordinates?: StartCoordinates;
  startFilters?: Partial<RestaurantMapFilters>;
}

export function RestaurantMapProvider({
  children,
  restaurants,
  startCoordinates,
  startFilters = {},
}: RestaurantMapProviderProps) {
  const carChargerCompanies = getCarChargerCompanies(restaurants);
  const filters = initialValue.filters;
  filters.ev.company =
    filters?.ev && initialValue.filters.ev.company.length == 0
      ? carChargerCompanies
      : initialValue.filters.ev.company;

  const [restaurantMap, dispatch] = useReducer(reducer, {
    ...initialValue,
    startCoordinates,
    filters: { ...filters, ...startFilters },
    restaurants,
    filteredRestaurants: restaurants,
    carChargerCompanies,
  });

  return (
    <RestaurantMapContext.Provider value={restaurantMap}>
      <RestaurantMapDispatchContext.Provider value={dispatch}>
        {children}
      </RestaurantMapDispatchContext.Provider>
    </RestaurantMapContext.Provider>
  );
}
